<template>
  <div class="container-fluid">
    <div class="row justify-content-md-center" v-cloak v-if="menuPermissions.length === 0">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div v-else>
      <div class="row page-titles">
        <div class="col-12 align-self-center">
          <h3 class="text-themecolor m-b-0 m-t-0">References & Settings</h3>
          <ol class="breadcrumb" v-if="!isMobile">
            <li class="breadcrumb-item">
              <router-link to="/">Home</router-link>
            </li>
            <li class="breadcrumb-item active">References & Settings</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <template v-for="(row , index) in menuPermissions">
          <router-link :to="row.path" class="col-md-6 col-lg-3 col-xlg-3" :key="index">
            <div class="card card-inverse">
              <div class="box text-center">
                <h1 class="font-light">
                  <i :class="['mdi text-dark', row.icon]"></i>
                </h1>
                <h6 class="text-dark">{{row.title}}</h6>
              </div>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/pageTitle/index";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    Header,
    RotateSquare5
  },
  computed: {
    menuPermissions() {
      this.menuAllowed = [];
      if (this.$store.state.profile.permissions) {
        this.menu.forEach((element, index) => {
          var permisions = Object.assign(
            {},
            this.$store.state.profile.permissions[element.name]
          );
          if (permisions.read) {
            this.menuAllowed.push(element);
          }
        });
      }

      return this.menuAllowed;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      state: {
        isEdit: false,
        isAdd: false,
        isDetail: false,
        loaded: false
      },
      menu: [
        {
          title: "User Management",
          path: "/user",
          name: "user",
          icon: "mdi-account"
        },
        {
          title: "User Group Management",
          path: "/groups",
          name: "groups",
          icon: "mdi-account-multiple"
        },
        {
          title: "Unit Management",
          path: "/unit",
          name: "unit",
          icon: "mdi-folder"
        },
        {
          title: "Room Management",
          path: "/rooms",
          name: "room",
          icon: "mdi-border-all"
        },
        {
          title: "Facilities Management",
          path: "/facilities",
          name: "facility",
          icon: "mdi-package-variant"
        },
        {
          title: "Jabatan Management",
          path: "/jabatan",
          name: "position",
          icon: " mdi-view-agenda"
        },
        {
          title: "Indeks Surat Management",
          path: "/indeksSurat",
          name: "",
          icon: "mdi-home-variant"
        },
        {
          title: "Jenis Surat Management",
          path: "/jenisSurat",
          name: "document_type",
          icon: "mdi-email"
        },
        {
          title: "Klasifikasi Surat Management",
          path: "/clasification",
          name: "clasification_doc",
          icon: "mdi-monitor"
        },
        {
          title: "Template Management",
          path: "/templateDokumen",
          name: "document_template",
          icon: "mdi-view-quilt"
        },
        {
          title: "Jenis Management",
          path: "/jenisDokumen",
          name: "jenisdokumen",
          icon: "mdi-account-circle"
        },
        {
          title: "Tipe Surat",
          path: "/typeSurat",
          name: "facility",
          icon: "mdi-tab"
        },
        {
          title: "Asal Surat",
          path: "/asalSurat",
          name: "facility",
          icon: "mdi-message-text"
        }
      ],
      menuAllowed: []
    };
  }
};
</script>
